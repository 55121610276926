<template>
  <div class="about-wrapper">
    <ul>
      <li>
        <span>软件介绍</span>
        <p>
          塔尖是一个互动交流交友软件，线上就可以根据文本或视频语音与周边的异性朋友开展沟通交流，
          完成交朋友、告别单身、婚恋交友等; 集生活、美食、娱乐出行的社交APP
          邂逅一场高品质的社交体验。
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.about-wrapper {
  width: 800px;
  height: 400px;
  margin-top: 30px;
  margin-left: 200px;
  box-sizing: border-box;
}
.about-wrapper p {
  padding: 70px;
}
</style>